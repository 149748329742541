import React,{useEffect}  from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiLinux,
  SiVisualstudiocode,
  SiPostman,
  SiHeroku,
  SiVercel,
} from "react-icons/si";
import AOS from 'aos'
import "aos/dist/aos.css"
import './Know.css';
import carl from "../../Assets/carl.jpeg"
import antonin from "../../Assets/antonin.png"
import wawens from "../../Assets/wawens.jpg"
import Julie from "../../Assets/Julie.jpeg"
import { FaLinkedinIn } from "react-icons/fa";

function Know() {

  useEffect(() => {
      AOS.init({ duration: 3000 })
  }, [])


return (
    <div>
        <div class="container-h2 presen-h2">
            <h2>
      Notre équipe
            </h2>
        </div>

        <section className='container-know' data-aos="">
            <div>
            </div> 
            <div className='kards-grids'>
                <div class="kard kard0">
                    <div class="overlay-know">
                        <div class="contents-knows">
                            <h2><strong>VICTOR</strong> Carl</h2>
                            <p> <h3>CEO</h3> </p>
                            <p> Chief executif officer of Ecoeasier </p>
                            {/*<a class="learn-more-btn" href="#">En savoir plus</a>*/}
                        </div>
                        <div class="icons">
                            <a href="https://www.linkedin.com/in/carl-victor/"><div className='rs-know-count'><FaLinkedinIn /></div></a>
                            {/*<a href="#"><div className='rs-know-count'><i class="fa fa-youtube"></i></div></a>
                            <a href="#"><div className='rs-know-count'><i class="fa fa-instagram"></i></div></a>
                            <a href="#"><div className='rs-know-count'><i class="fa fa-twitter"></i></div></a>*/}
                        </div>
                    </div>
                </div>
                

                <div class="kard kard1">
                    <div class="overlay-know">
                        <div class="contents-knows">
                            <h2><strong>IRLES</strong> Antonin</h2>
                            <p> <h3>COO</h3> </p>
                            <p> Chief Operating Officer of Ecoeasier </p>
                            {/*<a class="learn-more-btn" href="#">En savoir plus</a>*/}
                        </div>

                        <div class="icons">
                        <a href="https://www.linkedin.com/in/antonin-irl%C3%A8s-2a05b41aa/"><div className='rs-know-count'><FaLinkedinIn /></div></a>                            
                        {/*<a href="#"><div className='rs-know-count'><i class="fa fa-youtube"></i></div></a>
                            <a href="#"><div className='rs-know-count'><i class="fa fa-instagram"></i></div></a>
                          <a href="#"><div className='rs-know-count'><i class="fa fa-twitter"></i></div></a>*/}
                        </div>
                    </div>
                </div>

                <div class="kard kard2">
                    <div class="overlay-know">
                        <div class="contents-knows">
                            <h2><strong>JANVIER</strong> Wawens</h2>
                            <p><h3>CTO</h3></p>
                            <p> Chief technology officer  of Ecoeasier </p>
                            {/*<a class="learn-more-btn" href="#">En savoir plus</a>*/}
                        </div>
                        <div class="icons">
                        <a href="https://www.linkedin.com/in/wawens-janvier-7097411b7/"><div className='rs-know-count'><FaLinkedinIn /></div></a>                          
                          {/*<a href="#"><div className='rs-know-count'><i class="fa fa-youtube"></i></div></a>
                            <a href="#"><div className='rs-know-count'><i class="fa fa-instagram"></i></div></a>
                        <a href="#"><div className='rs-know-count'><i class="fa fa-twitter"></i></div></a>*/}
                        </div>
                    </div>
                </div>

                <div class="kard kard3">
                    <div class="overlay-know">
                        <div class="contents-knows">
                            <h2><strong>IRLES</strong> Julie</h2>
                            <p><h3>CMO</h3></p>
                            <p> Chief marketing officer of Ecoeasier </p>
                            {/*<a class="learn-more-btn" href="#">En savoir plus</a>*/}
                        </div>
                        <div class="icons">
                        <a href="https://www.linkedin.com/in/julie-irl%C3%A8s-934a80207/"><div className='rs-know-count'><FaLinkedinIn /></div></a>                            
                       {/* <a href="#"><div className='rs-know-count'><i class="fa fa-youtube"></i></div></a>
                            <a href="#"><div className='rs-know-count'><i class="fa fa-instagram"></i></div></a>
                      <a href="#"><div className='rs-know-count'><i class="fa fa-twitter"></i></div></a>*/}
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
)
}

export default Know




{/*function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiLinux />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVercel />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHeroku />
      </Col>
    </Row>
  );
}

export default Toolstack;*/}
