import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/tmp-removebg-preview.png";
import Particle from "../Particle";
import Home2 from "./Home2";
//import Type from "./Type";
import Acceuil from "./Type"

function Home() {
  return (
    <section className="section-top">
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">



              <h1 style={{ paddingBottom: 15 }} className="heading">
               Bienvenue sur   <strong className="main-name"> Ecoeasier  </strong>
                {" "}
                <span className="wave" role="img" aria-labelledby="wave">
                👋
                </span>
              </h1>

              <h1 className="heading-name">
                
              
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
              <Acceuil />
               
                {/*<Type />*/}
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
       <Home2 />
    </section>
  );
}

export default Home;
