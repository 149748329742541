import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="main"> Qu'est ce qu'Ecoeasier 🌱 ?
 </span> 
            </h1>
            <p className="home-about-body">
            <p><strong className="main-name"> Ecoeasier  </strong> est un projet utilisant la technologie de la blockchain, afin de favoriser l’utilisation des énergies renouvelable ce qui améliorerais considérablement notre <strong className="main-name"> impact  </strong>  sur l’environnement</p>
                        <p>
                            Le réseau Ecoeasier permet de posséder l’énergie produite par son système de production d’énergie renouvelables.
                            De ce fait, chaque utilisateur sera en mesure de percevoir directement les bénéfices liés à la vente d’énergie, si celle-ci n’est pas utilisé.
                        </p>
                        <p>
                            En effet, utiliser une énergie qui est décentralisée et qui peut nous appartenir permettrait à chacun de s’impliquer
                            et d’être concerné dans la lutte contre le dérèglement climatique.
                        </p>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            
          
            <ul className="home-about-social-links">
              <li className="social-icons">
               {/* <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
  </a>*/}
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/ecoeasierfrance?s=21"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/company/ecoeasier/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://instagram.com/ecoeasierfrance?igshid=OGQ5ZDc2ODk2ZA=="
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
