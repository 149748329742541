import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            
            <br /><div>
  
            <p>Nous sommes deux étudiants en école d'ingénieur et notre idée est née du constat sur l'urgence climatique et du besoin de responsabilisation citoyenne. Nos parcours professionnels en alternance, dans différentes entreprises, nous ont donné une connaissance approfondie du marché et des problématiques environnementales actuelles.</p>

<p>Au fil de nos expériences, nous avons appris à chercher des solutions innovantes pour répondre aux défis de notre temps. C'est ainsi que nous avons commencé à réfléchir à une approche novatrice pour responsabiliser chaque citoyen dans la lutte contre le réchauffement climatique.</p>

<p>L'idée de la tokenisation de l'énergie nous est apparue comme une solution intéressante pour permettre à chaque citoyen de contrôler sa propre consommation d'énergie. En créant une économie de l'énergie, nous pourrions récompenser les comportements éco-responsables et encourager la transition énergétique vers un modèle plus durable et respectueux de l'environnement.</p>

<p>En somme, c'est la combinaison de nos compétences acquises lors de nos alternances et de notre désir de trouver des solutions innovantes face aux problématiques environnementales actuelles qui nous a inspiré ce projet. Notre objectif est de faire de notre entreprise un acteur clé de la transition énergétique, en responsabilisant les citoyens et en les incitant à adopter des comportements éco-responsables.</p>
</div>

          </p>
        
        
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
