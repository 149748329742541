import React from "react";
import Typewriter from "typewriter-effect";
import Typed from "react-typed";
import classes from './Type.css';



export default function Acceuil() {

  const textLine = [
      `Ecoeasier est un système basée sur la technologie de la blockchain permettant  de faire des économies d’énergies en favorisant l’utilisation des <b id="renouvellable">Energies Renouvelables</b>.  `,
      `Ecoeasier est un système basée sur la technologie de la blockchain permettant  de faire des économies d’énergies en favorisant l’utilisation de  <b id="solaire">l'Energie Solaire</b>.  `,
      `Ecoeasier est un système basée sur la technologie de la blockchain permettant  de faire des économies d’énergies en favorisant l’utilisation de  <b id="eolienne">l'Energie Eolienne</b>.  `,
      `Ecoeasier est un système basée sur la technologie de la blockchain permettant  de faire des économies d’énergies en favorisant l’utilisation de  <b id="hydraulique">l'Energie Hydraulique</b>.  `,
      `Ecoeasier est un système basée sur la technologie de la blockchain permettant  de faire des économies d’énergies en favorisant l’utilisation de  <b id="geothermique">l'Energie Géothermique</b>.  `,
      `Ecoeasier est un système basée sur la technologie de la blockchain permettant  de faire des économies d’énergies en favorisant l’utilisation de  <b id="biomasse">l'Energie Biomasse</b>.  `,
      `Ecoeasier est un système basée sur la technologie de la blockchain permettant  de faire des économies d’énergies en favorisant l’utilisation de  <b id="biogaz">la Biogaz</b>.  `,
  ];

  return (
    <div>
        <section>
        <div className='animated-typing'>
        
            
                    <Typed
                strings={textLine}
                typeSpeed={10}
                backSpeed={10}
                loop      />

        </div>
        <a href=''> </a>
        </section>
       
    </div>
)
}
























/*function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Developer",
          "Deep Learning Engineer",
          "MERN Stack Developer",
          "Open Source Contributor",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Acceuil;*/
