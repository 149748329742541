import React from "react";
import { Col, Row } from "react-bootstrap";
import logoPepiteIdf from "../../Assets/logo-pepite-idf.svg"
import logoPepite3EF from "../../Assets/logo.svg"
import logoPepitestartup from "../../Assets/Logo-partenaire-marque-blanche-Pepite.png"
import logoschoolab from "../../Assets/schoolab-logo-white.svg"
import stationF from "../../Assets/Logo_STATION_F.svg"
import Esieeparis from "../../Assets/Logo_ESIEE_Paris.svg.png"
import Esieeit from "../../Assets/logo-esiee-it-en.png"
import logonomadic from "../../Assets/logonomadic.svg"
import logotezos from "../../Assets/tezos-logo-1.svg"
/*import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
} from "react-icons/di";
import {
  SiPytorch,
  SiFirebase,
  SiNextdotjs,
} from "react-icons/si";*/

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
      <img src={logoPepiteIdf} alt="logopepite" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={logoPepite3EF} alt="logopepite" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={logoPepitestartup} alt="logopepite" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={logoschoolab} alt="logopepite" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={stationF} alt="logopepite" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={Esieeit} alt="C++ Logo" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />

      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={Esieeparis} alt="C++ Logo" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={logonomadic} alt="logopepite" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={logotezos} alt="C++ Logo" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />

      </Col>
      {/*<Col xs={4} md={2} className="tech-icons">
        <SiPytorch />
  </Col>*/}
    </Row>
  );
}

export default Techstack;
